var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (Math, view) {pug_mixins["price"] = pug_interp = function(name, p, nameWithoutCurrency){
var block = (this && this.block), attributes = (this && this.attributes) || {};
var origPrice = p.priceStringNoSymbol()
if (origPrice % 1 === 0) {
origPrice = Math.floor(origPrice);
}
var salePrice = p.adjustedPriceStringNoSymbol()
if (salePrice % 1 === 0) {
salePrice = Math.floor(salePrice);
}
if (p.get('formattedAmount')) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"price\""+pug.attr("data-i18n", nameWithoutCurrency, true, true)+pug.attr("data-i18n-options", {price: p.get('formattedAmount')}, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
else
if (origPrice == salePrice) {
pug_html = pug_html + "\u003Cdiv" + (" class=\"price\""+pug.attr("data-i18n", name, true, true)+pug.attr("data-i18n-options", {price:origPrice}, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
else
if (view.basicCoupon && view.basicCoupon.duration === 'once') {
pug_html = pug_html + "\u003Cdiv" + (" class=\"price strike-price\""+pug.attr("data-i18n", name, true, true)+pug.attr("data-i18n-options", {price:origPrice}, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv" + (" class=\"new-price\""+" data-i18n=\"subscribe.first_month_price\""+pug.attr("data-i18n-options", {price:salePrice}, true, true)) + "\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv\u003E\u003Cspan" + (" class=\"old-price\""+pug.attr("data-i18n", name, true, true)+pug.attr("data-i18n-options", {price:origPrice}, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003Cspan" + (" class=\"price\""+pug.attr("data-i18n", name, true, true)+pug.attr("data-i18n-options", {price:salePrice}, true, true)) + "\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
}
};
pug_html = pug_html + "\u003Cdiv class=\"modal-dialog\"\u003E\u003Cdiv class=\"modal-content\"\u003E";
if (view.state === 'purchasing') {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-info\" data-i18n=\"buy_gems.purchasing\"\u003E\u003C\u002Fdiv\u003E";
}
else
if (view.state === 'retrying') {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger\" id=\"retrying-alert\" data-i18n=\"buy_gems.retrying\"\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cimg id=\"subscribe-background\" src=\"\u002Fimages\u002Fpages\u002Fplay\u002Fmodal\u002Fsubscribe-background-blank.png\"\u003E\u003Ch1 data-i18n=\"subscribe.subscribe_modal_title\"\u003E\u003C\u002Fh1\u003E\u003Cdiv id=\"close-modal\"\u003E\u003Cspan class=\"glyphicon glyphicon-remove\"\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"paper-area\"\u003E\u003Cdiv class=\"benefits-header text-center\" data-i18n=\"[html]subscribe.comparison_blurb\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"container-fluid\"\u003E\u003Cdiv class=\"row\"\u003E\u003Cdiv class=\"col-xs-5 option-col col-xs-offset-1\"\u003E\u003Cimg src=\"\u002Fimages\u002Fpages\u002Fplay\u002Fmodal\u002Fthree-pets.png\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-xs-6\"\u003E\u003Cul class=\"features-list\"\u003E\u003Cli data-i18n=\"subscribe.feature_level_access\"\u003E\u003C\u002Fli\u003E\u003Cli data-i18n=\"subscribe.feature_heroes\"\u003E\u003C\u002Fli\u003E\u003Cli data-i18n=\"subscribe.feature_learn\"\u003E\u003C\u002Fli\u003E";
if (view.basicProduct) {
pug_html = pug_html + "\u003Cli" + (" data-i18n=\"subscribe.feature_gems\""+pug.attr("data-i18n-options", {gems: view.basicProduct.get('gems').toLocaleString(), gems12: (view.basicProduct.get('gems') * 12).toLocaleString()}, true, true)) + "\u003E\u003C\u002Fli\u003E";
}
pug_html = pug_html + "\u003Cli data-i18n=\"subscribe.feature_ai_level_bot\"\u003E\u003C\u002Fli\u003E\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Chr\u003E";
if (view.lifetimeProduct) {
pug_html = pug_html + "\u003Cdiv class=\"short-row row\"\u003E";
if (view.basicProduct && !view.hideMonthlySub) {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-5 option-col col-xs-offset-1\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"option-col .col-xs-5\"\u003E\u003Cdiv class=\"option-header best-deal\" data-i18n=\"subscribe.best_deal\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"option-col .col-xs-12\"\u003E\u003Cdiv class=\"option-header best-deal\" data-i18n=\"subscribe.best_deal\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"row\"\u003E";
var secondRowClass = '.col-xs-5'
if (view.basicProduct && !view.hideMonthlySub) {
pug_html = pug_html + "\u003Cdiv class=\"col-xs-5 option-col col-xs-offset-1\"\u003E";
pug_mixins["price"]("subscribe.month_price", view.basicProduct, 'subscribe.month_price_without_currency');
pug_html = pug_html + "\u003Cdiv class=\"option-header price-subtext text-center\" data-i18n=\"subscribe.stripe_description\"\u003E\u003C\u002Fdiv\u003E\u003Cbutton class=\"btn btn-lg btn-illustrated purchase-button\"\u003E\u003Cspan class=\"buy-now-text\" data-i18n=\"subscribe.buy_now\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"buy-lock-icon\"\u003E&#x1F512;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
else {
var secondRowClass = '.col-xs-12'
}
if (view.basicProductAnnual) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["option-col",secondRowClass], [false,true]), false, true)) + "\u003E";
pug_mixins["price"]("subscribe.year_price", view.basicProductAnnual, 'subscribe.year_price_without_currency');
pug_html = pug_html + "\u003Cdiv class=\"option-header price-subtext text-center\" data-i18n=\"subscribe.year_subscription\"\u003E\u003C\u002Fdiv\u003E";
if (view.paymentProcessor === 'PayPal') {
pug_html = pug_html + "\u003Cdiv id=\"paypal-button-container\"\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cbutton class=\"stripe-annual-button btn btn-lg btn-illustrated\"\u003E\u003Cspan class=\"buy-now-text\" data-i18n=\"subscribe.buy_now\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"buy-lock-icon\"\u003E&#x1F512;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else
if (view.lifetimeProduct) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["option-col",secondRowClass], [false,true]), false, true)) + "\u003E";
pug_mixins["price"]("subscribe.lifetime_price", view.lifetimeProduct, "subscribe.lifetime_price");
pug_html = pug_html + "\u003Cdiv class=\"option-header price-subtext text-center\" data-i18n=\"subscribe.lifetime\"\u003E\u003C\u002Fdiv\u003E";
if (view.paymentProcessor === 'PayPal') {
pug_html = pug_html + "\u003Cdiv id=\"paypal-button-container\"\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cbutton class=\"stripe-lifetime-button btn btn-lg btn-illustrated\"\u003E\u003Cspan class=\"buy-now-text\" data-i18n=\"subscribe.buy_now\"\u003E\u003C\u002Fspan\u003E\u003Cspan class=\"buy-lock-icon\"\u003E&#x1F512;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (view.isParentHomeAccount && !view.purchasingForId) {
pug_html = pug_html + "\u003Cdiv class=\"parent-account text-center\"\u003E\u003Cp class=\"warning-blurb\" data-i18n=\"subscribe.sure_buy_as_parent\"\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"support-blurb text-center\"\u003E\u003Cp\u003E\u003Cspan data-i18n=\"subscribe.support_part1\"\u003E\u003C\u002Fspan\u003E" + (pug.escape(null == (pug_interp = " ") ? "" : pug_interp)) + "\u003Ca href=\"mailto:support@codecombat.com\" data-i18n=\"subscribe.support_part2\"\u003E\u003C\u002Fa\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (view.state === 'declined') {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger alert-dismissible\" id=\"declined-alert\"\u003E\u003Cspan data-i18n=\"buy_gems.declined\"\u003E\u003C\u002Fspan\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"alert\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E";
}
if (view.state === 'error') {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger alert-dismissible\" id=\"error-alert\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"alert\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cp\u003E" + (pug.escape(null == (pug_interp = view.stateMessage) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
if (view.state === 'unknown_error') {
pug_html = pug_html + "\u003Cdiv class=\"alert alert-danger alert-dismissible\" id=\"error-alert\"\u003E\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"alert\"\u003E\u003Cspan aria-hidden=\"true\"\u003E&times;\u003C\u002Fspan\u003E\u003C\u002Fbutton\u003E\u003Cp data-i18n=\"loading_error.unknown\"\u003E\u003C\u002Fp\u003E\u003Cp\u003E" + (pug.escape(null == (pug_interp = view.stateMessage) ? "" : pug_interp)) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"Math" in locals_for_with?locals_for_with.Math:typeof Math!=="undefined"?Math:undefined,"view" in locals_for_with?locals_for_with.view:typeof view!=="undefined"?view:undefined));;return pug_html;};
module.exports = template;